import React, { createContext, ReactNode, Reducer, useReducer } from "react"
import { useContext } from "react"

enum ACTION_TYPES {
  HIDE = "HIDE",
  SHOW = "SHOW",
}

type Action =
  | { type: ACTION_TYPES.SHOW; text: string }
  | {
      type: ACTION_TYPES.HIDE
    }

interface State {
  show: boolean
  text: string
}

const INITIAL_STATE: State = {
  show: false,
  text: "",
}

const reducer: Reducer<State, Action> = (
  prevState: State,
  action: Action
): State => {
  switch (action.type) {
    case ACTION_TYPES.SHOW:
      return {
        ...prevState,
        text: action.text,
        show: true,
      }
    case ACTION_TYPES.HIDE:
      return {
        ...prevState,
        show: false,
      }
    default:
      return INITIAL_STATE
  }
}

export const Context = createContext<{
  state: typeof INITIAL_STATE
  dispatch: (action: Action) => void
}>({
  state: INITIAL_STATE,
  dispatch: () => {},
})

export const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  )
}

export const useNotificationStore = () => {
  const { state, dispatch } = useContext(Context)

  const hideNotification = () => {
    dispatch({ type: ACTION_TYPES.HIDE })
  }

  const showNotification = (text: string) => {
    dispatch({ type: ACTION_TYPES.SHOW, text })

    setTimeout(() => {
      dispatch({ type: ACTION_TYPES.HIDE })
    }, 1500)
  }

  return {
    state,
    hideNotification,
    showNotification,
  }
}
