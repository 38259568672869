import React from "react"
import { ThemeProvider } from "emotion-theming"
import { ModalProvider, NotificationProvider } from "./src/context/stores"
import { theme } from "./src/context/theme"

import "prismjs/themes/prism.css"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <NotificationProvider>
      <ModalProvider>{element}</ModalProvider>
    </NotificationProvider>
  </ThemeProvider>
)
